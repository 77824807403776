body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  /* font-family: Futura ND for Nike 365 Cn; */
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0px;
  font-family: "Futura",
}

h1 {
  text-transform: uppercase;
  font-size: 72px;
  line-height: 87px;
}

h2 {
  font-family: "Helvetica Neue";
  font-size: 58px;
  font-weight: 500;
}


h3 {
  font-size: 22px;
  line-height: 26.4px;
}


h4 {
  font-size: 18px;
  line-height: 21.64px;
  text-transform: uppercase;
}

h5 {
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
}

p, li {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 400;
}

p.lighter, li.lighter {
  font-weight: 300;
}

li {
  list-style-type: disc;
  margin-left: 20px;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
