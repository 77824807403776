.kittySearch {
  position: relative;
  z-index: 1001;
  padding-top: 20px;
}

.quantity {
  height: 32px;
}

input[type="number"]
{
  -moz-appearance: textfield;
}

input[type="number"] {
  margin: 0;
}

input[type="number"]:focus, input[type="submit"]:focus {
  outline: 0;
}

input[type="number"] {
  margin: 0;
  height: 32px;
  padding: 0 10px;
  width: 72px;
  border: 1px #999 solid;
  border-radius: 10px;
  text-align: center;
  margin-left: 4px;
}

img.submit {
  cursor: pointer;
  width: 32px;
  height: auto;
  vertical-align: baseline;
  margin-bottom: -15px;
  margin-left: 10px;
}

img.submit.disabled {
  opacity: 0.6;
  cursor: default;
}

img.submit:last-child {
  width: 20px;
  height: auto;
  vertical-align: baseline;
  margin-bottom: -7px;
  margin-left: 10px;
}

/* Error message */
form span {
  display: block;
  color: #F00;
  margin-top: -64px;
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 12px;
}
